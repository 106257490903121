// Generated by Framer (d213f04)

import { addFonts, cx, CycleVariantState, SVG, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["INYKJwqLj"];

const variantClassNames = {INYKJwqLj: "framer-v-1wswe9o"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "INYKJwqLj", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-FDj0w", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1wswe9o", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"INYKJwqLj"} ref={ref} style={{...style}}><SVG className={"framer-d7prgm"} data-framer-name={"graphic"} layout={"position"} layoutDependency={layoutDependency} layoutId={"TdCezEEPO"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 24 24\"><path d=\"M 4.75 7.75 C 4.75 6.093 6.093 4.75 7.75 4.75 L 16.25 4.75 C 17.907 4.75 19.25 6.093 19.25 7.75 L 19.25 16.25 C 19.25 17.907 17.907 19.25 16.25 19.25 L 7.75 19.25 C 6.093 19.25 4.75 17.907 4.75 16.25 Z\" fill=\"transparent\" stroke-width=\"1.5\" stroke=\"var(--token-72be4b58-ad30-40d4-9403-e97ffbb2d1b3, rgb(0, 0, 0)) /* {&quot;name&quot;:&quot;Text&quot;} */\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-dasharray=\"\"></path><path d=\"M 10.75 16.25 L 10.75 14 C 10.75 12.757 11.757 11.75 13 11.75 C 14.243 11.75 15.25 12.757 15.25 14 L 15.25 16.25 M 10.75 11.75 L 10.75 16.25 M 7.75 11.75 L 7.75 16.25 M 7.75 8.75 L 7.75 9.25\" fill=\"transparent\" stroke-width=\"1.5\" stroke=\"var(--token-72be4b58-ad30-40d4-9403-e97ffbb2d1b3, rgb(0, 0, 0)) /* {&quot;name&quot;:&quot;Text&quot;} */\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-dasharray=\"\"></path></svg>"} svgContentId={2915444542} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-FDj0w [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-FDj0w .framer-i22rka { display: block; }", ".framer-FDj0w .framer-1wswe9o { height: 24px; overflow: visible; position: relative; width: 24px; }", ".framer-FDj0w .framer-d7prgm { flex: none; height: 24px; left: 0px; position: absolute; top: 0px; width: 24px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 24
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 */
const FramervRiJwIK1Y: React.ComponentType<Props> = withCSS(Component, css, "framer-FDj0w") as typeof Component;
export default FramervRiJwIK1Y;

FramervRiJwIK1Y.displayName = "Nav/Icon -- Linkedin";

FramervRiJwIK1Y.defaultProps = {height: 24, width: 24};

addFonts(FramervRiJwIK1Y, [])